import React, { Component } from "react";

class Loading extends Component{

    render(){
        return(
            <>asdf3333</>
        )
    }
}


export default Loading;